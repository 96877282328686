import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"disabled":_vm.suppressConfirmation,"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_vm._t("default",function(){return [_c('button-action',_vm._g(_vm._b({attrs:{"data-test":"sample","icon-name":_vm.$attrs.icon ? 'delete' : '',"label":_vm.$attrs.label || _vm.$t('Delete'),"color":_vm.$attrs.icon ? '' : 'error',"depressed":""}},'button-action',Object.assign({}, _vm.$attrs, attrs),false),Object.assign({}, _vm.$listeners, on)))]},{"activator":{ attrs: attrs, on: on }})]}}],null,true),model:{value:(_vm.isDialogOpen),callback:function ($$v) {_vm.isDialogOpen=$$v},expression:"isDialogOpen"}},[(_vm.isDialogOpen)?_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_vm._t("dialog-title",function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Delete confirmation')))])]}),_c(VBtn,{attrs:{"icon":""},on:{"click":function($event){_vm.isDialogOpen = false}}},[_c(VIcon,[_vm._v("close")])],1)],2),_c(VDivider),_c(VCardText,[_c('div',{staticClass:"d-flex mt-4"},[_vm._t("dialog-content",function(){return [_c('span',[_vm._v(_vm._s(_vm.$t('Are you sure'))+"?")])]})],2)]),_c(VDivider),_vm._t("dialog-actions",function(){return [_c(VCardActions,{staticClass:"d-flex justify-space-between"},[_c(VBtn,{attrs:{"depressed":"","color":"secondary"},on:{"click":function($event){_vm.isDialogOpen = false}}},[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")]),_c(VBtn,{attrs:{"depressed":"","color":"primary","text":""},on:{"click":_vm.confirmHandler}},[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")])],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }